import {Typography} from '@mui/material';
import {FC, ReactElement} from 'react';
import {FormattedMessage, useIntl} from 'react-intl';
import {Span} from '../../styledComponents/Span';
import {TooltipIcon} from '../helperComponents/TooltipIcon';
import {PenIcon} from '../icons/PenIcon';

type DetailValueTitleProps = {
    readonly title: string;
    readonly typPrilohy?: ReactElement;
    readonly bigTitle?: boolean;
    readonly handleOnClickModal?: () => void;
};

export const DetailValueTitle: FC<DetailValueTitleProps> = ({title, handleOnClickModal, bigTitle, typPrilohy}) => {
    const {formatMessage} = useIntl();
    return (
        <Typography
            variant={'subtitle2'}
            component="div"
            sx={(theme) => ({
                ...(bigTitle
                    ? {
                          display: 'flex',
                          alignItems: 'center',
                          overflow: 'hidden',
                          fontWeight: 'bold',
                          '&:after': {
                              content: '""',
                              display: 'inline-block',
                              height: '0.7em',
                              verticalAlign: 'bottom',
                              width: '100%',
                              marginRight: '-100%',
                              marginLeft: '5px',
                              marginTop: 10,
                              borderTop: `2px solid ${theme.palette.grey[300]}`,
                          },
                      }
                    : {
                          display: 'flex',
                          alignItems: 'center',
                          overflow: 'hidden',
                          fontWeight: 'bold',
                      }),
            })}
        >
            <FormattedMessage id={title} values={{typPrilohy}} />
            {typeof handleOnClickModal === 'function' && (
                <TooltipIcon textRaw={formatMessage({id: 'button.upravit.blok'})} placement="top">
                    <Span>
                        <PenIcon
                            sx={{
                                marginLeft: 10,
                                '&:hover': {
                                    cursor: 'pointer',
                                },
                            }}
                            onClick={handleOnClickModal}
                            color="primary"
                            fontSize="small"
                        />
                    </Span>
                </TooltipIcon>
            )}
        </Typography>
    );
};
