import {ApiDateTime} from '@eon.cz/apollo13-graphql-web';
import {Edit as EditIcon} from '@mui/icons-material';
import {FC} from 'react';
import {FormattedDateTime} from '../date/FormattedDateTime';
import {TooltipIcon} from '../helperComponents/TooltipIcon';

type PrilohaPodepsalProps = {
    readonly podepsano: ApiDateTime;
};

export const PrilohaPodepsal: FC<PrilohaPodepsalProps> = ({podepsano}) => {
    const PrilohaPodepsalTooltip = () => <FormattedDateTime value={podepsano} />;

    return (
        <TooltipIcon htmlText={<PrilohaPodepsalTooltip />}>
            <EditIcon
                sx={{
                    position: 'relative',
                    top: 4,
                    width: 16,
                    height: 16,
                    color: 'text.secondary',
                }}
            />
        </TooltipIcon>
    );
};
