import {SvgIcon, SvgIconProps} from '@mui/material';

type Props = SvgIconProps;

export const BinIcon = (props: Props) => (
    <SvgIcon {...props}>
        <path d="M 14.659 23.009 L 9.344 23.009 C 6.475 23.006 4.15 20.8 4.147 18.078 L 4.147 8.724 L 19.855 8.724 L 19.855 18.078 C 19.852 20.8 17.527 23.006 14.659 23.009 Z M 5.456 9.967 L 5.456 18.078 C 5.458 20.114 7.198 21.765 9.344 21.767 L 14.659 21.767 C 16.805 21.765 18.544 20.114 18.546 18.078 L 18.546 9.967 Z" />
        <path d="M 9.383 12.451 L 9.383 18.662 C 9.383 19.14 9.929 19.438 10.365 19.199 C 10.567 19.088 10.692 18.883 10.692 18.662 L 10.692 12.451 Z" />
        <path d="M 13.31 12.451 L 13.31 18.662 C 13.31 19.14 13.856 19.438 14.292 19.199 C 14.494 19.088 14.619 18.883 14.619 18.662 L 14.619 12.451 Z" />
        <path d="M 17.892 1.272 C 17.892 0.929 17.599 0.651 17.237 0.651 C 17.141 0.652 17.046 0.674 16.959 0.714 L 16.957 0.71 L 4.522 6.3 L 5.082 7.423 L 17.517 1.833 L 17.515 1.829 C 17.744 1.728 17.89 1.511 17.892 1.272 Z" />
    </SvgIcon>
);
