import {PrilohaTyp} from '@eon.cz/apollo13-graphql-web';
import difference from 'lodash/difference';
import uniqBy from 'lodash/uniqBy';
import {ReactNode} from 'react';
import {FormattedMessage} from 'react-intl';
import {MAX_FILE_B_SIZE, MAX_FILE_MB_SIZE, MIN_FILE_B_SIZE} from '../../../constants';
import {ValidationError} from '../../../utils/CommonTypes';
import {isNotNullOrUndefinedOrEmpty, isNullOrUndefinedOrEmpty} from '../../../utils/CommonUtils';
import {getFilenameExtension, getOriginalFilename, povoleneTypyPriloh, splitterPriloha} from './PrilohyUtils';

type PrilohaUploadValidationInput = {
    readonly typ?: any;
    readonly filename: string;
    readonly bytesLength: number;
    readonly mime?: string;
    readonly id?: number;
};

export type PrilohyArchivovaneValidationInput = PrilohaUploadValidationInput & {
    readonly index?: number;
};

export type PrilohaArchivovanaValidation = {
    readonly id?: string | number;
    readonly nazev?: string;
    readonly mime?: string;
    readonly velikost: number;
};

/** Číselník pro typ přílohy, jestli je k nahrání nebo již archivovaná */
export const DuplicitniNazevPrilohyTypPrilohy = {
    K_NAHRANI: 'K_NAHRANI',
    ARCHIVOVANA: 'ARCHIVOVANA',
} as const;

export type DuplicitniNazevPrilohyTypPrilohy = (typeof DuplicitniNazevPrilohyTypPrilohy)[keyof typeof DuplicitniNazevPrilohyTypPrilohy];
/**
 * Funkce na validaci příloh
 *
 * @param {PrilohaUploadValidationInput[]} prilohy
 * @param {PovoleneTypyPriloh} {typObjektu, typyPriloh}
 * @param {PrilohaArchivovanaValidation[]} [archivovane]
 */

export const validatePrilohy = (
    prilohy: PrilohaUploadValidationInput[],
    isAllSetTypyPriloh: boolean,
    validateVelikostAll: boolean,
    typyPriloh: PrilohaTyp[],
    archivovane?: PrilohaArchivovanaValidation[],
): ValidationError[] => {
    const validationErrors: ValidationError[] = [];
    if (prilohy && prilohy.length > 0) {
        pushIfNotNullOrUndefined(validationErrors, validatePocetPriloh(prilohy.length));
        if (validateVelikostAll) pushIfNotNullOrUndefined(validationErrors, validateVelikostPrilohyBytesAll(prilohy, archivovane));

        let index = 0;
        for (const priloha of prilohy) {
            let prilohaValidationsErrors: ValidationError[] = [];
            if (!isAllSetTypyPriloh) pushIfNotNullOrUndefined(prilohaValidationsErrors, validateTypPrilohy(priloha.typ, typyPriloh));
            pushIfNotNullOrUndefined(prilohaValidationsErrors, validateVelikostPrilohyBytes(priloha.bytesLength));
            pushIfNotNullOrUndefined(prilohaValidationsErrors, validateFormatPrilohy(priloha.filename));
            pushIfNotNullOrUndefined(prilohaValidationsErrors, validateNazevPrilohy(prilohy, null, priloha.id));

            // Přidá index přílohy
            prilohaValidationsErrors = prilohaValidationsErrors.map((validationError: ValidationError) => ({...validationError, index} as ValidationError));
            pushAll(validationErrors, prilohaValidationsErrors.filter(isNotNullOrUndefinedOrEmpty));
            index++;
        }
    }

    if (archivovane && archivovane.length > 0) {
        const prilohaValidationsErrors: ValidationError[] = [];
        for (const archivovana of archivovane) {
            pushIfNotNullOrUndefined(prilohaValidationsErrors, validateNazevPrilohy(prilohy, archivovana));
        }

        // Přidá index přílohy
        const valflattenValidationErrors = prilohaValidationsErrors.flat(1);

        // Potřebujeme najít,jestli již neexistuje tento chybový kod v proměnné validationErrors
        const duplicateError: ValidationError | undefined = validationErrors.find((validationError) => validationError.code === 'GENVAL048');
        pushAll(
            validationErrors,
            !!duplicateError ? valflattenValidationErrors.filter((priloha) => priloha.message !== duplicateError.message) : valflattenValidationErrors,
        );

        // pushAll(validationErrors, prilohaValidationsErrors.flat(1));
    }
    return validationErrors;
};

/**
 * Validace na duplicitní název přílohy a typ přílohy.V této validaci musíme porovnat názvy a typ přípony mezi přílohami k nahraní a i oproti přílohám archivovaným
 *
 * @param {PrilohyArchivovaneValidationInput[]} prilohy
 * @param {(PrilohaArchivovanaValidation | null)} archivovana
 * @param {(number | null)} [idPrilohy]
 */
export const validateNazevPrilohy = (
    prilohy: PrilohyArchivovaneValidationInput[],
    archivovana: PrilohaArchivovanaValidation | null,
    idPrilohy?: number | null,
) => {
    const prilohyOmitExt = prilohy.map((priloha) => ({...priloha, filename: getOriginalFilename(priloha.filename)}));

    const prilohyError = difference(prilohyOmitExt, uniqBy(prilohyOmitExt, 'filename'));

    const prilohyNazevDelkaError = prilohyOmitExt.filter((priloha) => priloha.filename.length > 100);

    // Funkce vrací pole duplikovaných příloh k nahrání oproti archivovaným a přidává jim správné indexy pro validační hlášku.Validuje se pouze v případě, kdy validujeme přílohy k nahrání oproti přílohám archivovaným.
    const archivovaneError =
        archivovana &&
        prilohy.reduce((nahraneErr, nahrana, index) => {
            return splitterPriloha({value: nahrana.filename.toLowerCase()}) === splitterPriloha({value: archivovana?.nazev?.toLowerCase() ?? ''})
                ? [
                      ...nahraneErr,
                      {
                          ...nahrana,
                          index,
                      },
                  ]
                : nahraneErr;
        }, [] as PrilohyArchivovaneValidationInput[]);

    const errors: ValidationError[] = [];
    if (archivovaneError && archivovaneError.length > 0) {
        archivovaneError.forEach((archivovanaError) => {
            errors.push({
                message: nazevPrilohyMessage(DuplicitniNazevPrilohyTypPrilohy.ARCHIVOVANA, archivovanaError.filename),
                index: archivovanaError.index,
            } as ValidationError);
        });
    }

    if (prilohyError && prilohyError.length > 0) {
        for (const prilohaError of prilohyError) {
            if (idPrilohy === prilohaError.id) {
                return {
                    message: nazevPrilohyMessage(DuplicitniNazevPrilohyTypPrilohy.K_NAHRANI, prilohaError.filename),
                    index: prilohaError.id,
                } as ValidationError;
            }
        }
    }
    if (prilohyNazevDelkaError && prilohyNazevDelkaError.length > 0) {
        for (const prilohaNazevDelkaError of prilohyNazevDelkaError) {
            if (idPrilohy === prilohaNazevDelkaError.id) {
                return {
                    message: <FormattedMessage id="priloha.nazev.error" />,
                    index: prilohaNazevDelkaError.id,
                } as ValidationError;
            }
        }
    }

    if (errors.length > 0) {
        return errors;
    }
};
/**
 * Funkce na validaci velikosti přlohy
 *
 * @param {number} currentSize
 */
export const validateVelikostPrilohyBytes = (currentSize: number) =>
    validateVelikostSouboru(MIN_FILE_B_SIZE, MAX_FILE_B_SIZE, MAX_FILE_MB_SIZE, currentSize, true);

/**
 * Funkce na validaci velikosti přlohy
 *
 * @param {number} currentSize
 */
export const validateVelikostPrilohyBytesAll = (prilohy: PrilohaUploadValidationInput[], archivovane?: PrilohaArchivovanaValidation[]) => {
    const remapArchivovane = (archivovane ?? [])?.map((archivovana) => ({bytesLength: archivovana.velikost, ...archivovana}));
    const sumSizeInBytes = [...prilohy, ...remapArchivovane].reduce((sum, priloha) => sum + priloha.bytesLength, 0);
    if (sumSizeInBytes > MAX_FILE_B_SIZE) {
        return {
            message: <FormattedMessage id="prilohy.max.file.size.all" />,
        } as ValidationError;
    }
};
/**
 * Funkce na validaci velikosti přlohy
 *
 * @param {number} currentSize
 */
export const validateVelikostPrilohyMbytes = (currentSize: number) =>
    validateVelikostSouboru(MIN_FILE_B_SIZE, MAX_FILE_B_SIZE, MAX_FILE_MB_SIZE, currentSize, false);
/**
 * Funkce na validaci počtu příloh
 *
 * @param currentCount
 */
export const validatePocetPriloh = (currentCount: number) => validatePocet(10, currentCount);

/**
 * Funkce na validaci typu přílohy
 *
 * @param {PrilohaTyp} typPrilohy
 * @param {PrilohaTyp[]} typyPriloh
 */

export const validateTypPrilohy = (typPrilohy: any | undefined, typyPriloh: any[]) => {
    if (typPrilohy && !typyPriloh.includes(typPrilohy)) {
        return {
            message: <FormattedMessage id="priloha.typ.prilohy.error" />,
        } as ValidationError;
    }
};
/**
 * Funkce na validaci formátu přílohy
 *
 * @param {string} filename
 */
export const validateFormatPrilohy = (filename: string) => {
    const ext = getFilenameExtension(filename);
    if (!isNullOrUndefinedOrEmpty(ext)) {
        const isPovolenyFormat = povoleneTypyPriloh.includes(ext?.toLowerCase() as string);
        if (!isPovolenyFormat) {
            return {
                message: <FormattedMessage id="prilohy.nepovoleny.format.extension" values={{povoleneTypy: povoleneTypyPriloh.join(',')}} />,
            } as ValidationError;
        }
    } else {
        // příloha nemá příponu
        return {
            message: <FormattedMessage id="prilohy.bez.prilohy" />,
        } as ValidationError;
    }
};

/**
 * Funkce na přidání chyby do validací
 *
 * @template T
 * @param {T[]} target
 * @param {T} item
 * @return {T[]}
 */
const pushIfNotNullOrUndefined = <T,>(target: T[], item: T): T[] => {
    if (item !== undefined && item !== null) {
        target.push(item);
    }
    return target;
};

/**
 * Funbkce na validaci počtu příloh
 *
 * @param {number} maxCount
 * @param {number} currentCount
 */
const validatePocet = (maxCount: number, currentCount: number) => {
    if (currentCount > maxCount) {
        return {message: <FormattedMessage id="yup.max" values={{value: maxCount}} />};
    }
};

/**
 * Funkce na validaci velikosti souboru
 *
 * @param {number} minBSize
 * @param {number} maxBSize
 * @param {number} maxMbSize
 * @param {number} currentSize
 * @param {boolean} inputInB
 */
const validateVelikostSouboru = (minBSize: number, maxBSize: number, maxMbSize: number, currentSize: number, inputInB: boolean) => {
    const sizeInB = inputInB ? currentSize : currentSize * 1024 ** 2;
    if (sizeInB > maxBSize || sizeInB < minBSize) {
        return {
            message: <FormattedMessage id="prilohy.nepovoleny.pocet" values={{minSize: minBSize, maxSize: maxMbSize}} />,
        };
    }
};
/**
 * Dunkce vrací textaci podle erroru
 *
 * @param {DuplicitniNazevPrilohyTypPrilohy} error
 * @param {string} nazev
 * @param form
 * @return {*}  {ReactNode}
 */
export const nazevPrilohyMessage = (error: DuplicitniNazevPrilohyTypPrilohy, nazev: string, form?: string): ReactNode =>
    error === DuplicitniNazevPrilohyTypPrilohy.ARCHIVOVANA ? (
        <FormattedMessage id="prilohy.nazev.exist" values={{nazev}} />
    ) : (
        <FormattedMessage id={form ? 'prilohy.nazev.duplicita.form' : 'prilohy.nazev.duplicita'} values={{nazev, form}} />
    );

const pushAll = <T,>(target: T[], items: T[]): T[] => {
    if (items !== undefined && items !== null && typeof items === 'object' && items.length > 0) {
        items.forEach((item) => (item ? target.push(item) : ''));
    }

    return target;
};
