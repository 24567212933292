type PrilohaVelikostProps = {
    readonly velikost: number;
};

const getUnitAndSize = (size: number) => {
    if (size > 1_000_000) {
        return [Math.round(size / 1000000), 'MB'];
    }
    if (size > 1000) {
        return [Math.round(size / 1000), 'kB'];
    }
    return [size, 'B'];
};

export const PrilohaVelikost = ({velikost}: PrilohaVelikostProps) => {
    const [sizeInUnit, unit] = getUnitAndSize(velikost);

    return (
        <>
            {sizeInUnit}&nbsp;{unit}
        </>
    );
};
