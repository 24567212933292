import {SvgIcon, SvgIconProps} from '@mui/material';

type Props = SvgIconProps;

export const JpegIcon = (props: Props) => (
    <SvgIcon {...props}>
        <path d="M 22.176 14.761 L 20.74 14.761 L 20.74 16.197 L 22.176 16.197 L 22.176 17.636 L 19.301 17.636 L 19.301 13.322 L 23.615 13.322 C 23.615 12.53 22.971 11.886 22.176 11.886 L 19.301 11.886 C 18.509 11.886 17.865 12.53 17.865 13.322 L 17.865 17.636 C 17.865 18.428 18.509 19.072 19.301 19.072 L 22.176 19.072 C 22.971 19.072 23.615 18.428 23.615 17.636 L 23.615 16.197 C 23.615 15.405 22.971 14.761 22.176 14.761 Z" />
        <path d="M 2.051 21.229 L 2.051 2.543 C 2.051 2.147 2.373 1.825 2.772 1.825 L 13.551 1.825 L 13.551 4.7 C 13.551 5.492 14.195 6.136 14.99 6.136 L 17.865 6.136 L 17.865 8.293 L 19.301 8.293 L 19.301 5.418 C 19.305 5.225 19.227 5.041 19.093 4.908 L 14.782 0.594 C 14.646 0.46 14.462 0.385 14.272 0.386 L 2.772 0.386 C 1.58 0.386 0.615 1.351 0.615 2.543 L 0.615 21.229 C 0.615 22.421 1.58 23.386 2.772 23.386 L 7.801 23.386 L 7.801 21.947 L 2.772 21.947 C 2.373 21.947 2.051 21.625 2.051 21.229 Z" />
        <path d="M 9.958 17.636 L 7.083 17.636 L 7.083 19.072 L 9.958 19.072 C 10.753 19.072 11.397 18.428 11.397 17.636 L 11.397 11.886 L 9.958 11.886 Z" />
        <path d="M 15.708 11.886 L 12.833 11.886 C 12.437 11.886 12.115 12.208 12.115 12.604 L 12.115 19.072 L 13.551 19.072 L 13.551 16.918 L 15.708 16.918 C 16.503 16.918 17.147 16.274 17.147 15.479 L 17.147 13.322 C 17.147 12.53 16.503 11.886 15.708 11.886 Z M 15.708 15.479 L 13.551 15.479 L 13.551 13.322 L 15.708 13.322 Z" />
    </SvgIcon>
);
