import {FormHelperText} from '@mui/material';
import {FC, ReactNode} from 'react';

type Props = {
    readonly error: ReactNode;
    readonly name: string;
};

/**
 * Komponenta zobrazuje jednu validační chybu u příloh. Buď
 */
export const PrilohyFormError: FC<Props> = ({error, name}) => (
    <FormHelperText
        sx={{
            margin: 0,
        }}
        error
        data-testid={name}
    >
        {error}
    </FormHelperText>
);
