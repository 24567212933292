import {List} from '@mui/material';
import {FC} from 'react';
import {PrilohaListItem} from './PrilohaListItem';
import {PrilohaAkce, PrilohaBase} from './utils/PrilohyUtils';

type Props = {
    /**
     * Seznam příloh
     */
    readonly list: PrilohaBase[];

    /**
     * Seznam akcí ke každé příloze
     */
    readonly actions: PrilohaAkce[];

    /**
     * Varianta komponenty (výchozí je "simple")
     */
    readonly variant?: 'simple' | 'dialog';

    /**
     * Zda se mají výběry typu přílohy u jednotlivých nahrávaných souborů adaptovat na modifikované seznamy povolených typů příloh.
     */
    readonly dynamicPrilohaTypVyber?: boolean;

    /**
     * Povolené typy příloh
     */
    readonly povoleneTypy?: unknown[];

    /**
     * U nahrávané přílohy změní její typ podle ID
     */
    readonly onChangeTypPrilohy?: (id: number, typ: unknown) => void;
};

const DEFAULT_VARIANT = 'simple';

/**
 * Zobrazí seznam příloh
 */
export const PrilohyList: FC<Props> = ({list, variant = DEFAULT_VARIANT, actions, onChangeTypPrilohy, dynamicPrilohaTypVyber, povoleneTypy}) => (
    <List
        sx={{
            width: '100%',
        }}
    >
        {list.map((priloha: PrilohaBase) => (
            <PrilohaListItem
                priloha={priloha}
                actions={actions}
                variant={variant}
                povoleneTypy={povoleneTypy}
                dynamicPrilohaTypVyber={dynamicPrilohaTypVyber}
                onChangeTypPrilohy={onChangeTypPrilohy}
                key={priloha.id}
            />
        ))}
    </List>
);
