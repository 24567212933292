import {PrilohaTyp} from '@eon.cz/apollo13-graphql-web';
import {Attachment as AttachmentIcon, PictureAsPdfOutlined} from '@mui/icons-material';
import {
    Avatar,
    FormControl,
    FormHelperText,
    IconButton,
    InputLabel,
    ListItem,
    ListItemAvatar,
    ListItemText,
    MenuItem,
    Select,
    SelectChangeEvent,
} from '@mui/material';
import {FC, Fragment, ReactNode, useState} from 'react';
import {FormattedMessage} from 'react-intl';
import {Div} from '../../styledComponents/Div';
import {Span} from '../../styledComponents/Span';
import {FIELD_WITH_ERROR_FLASH_CLASS} from '../helperComponents/ErrorFocus';
import {TooltipIcon} from '../helperComponents/TooltipIcon';
import {CsvIcon} from '../icons/CsvIcon';
import {ExcelIcon} from '../icons/ExcelIcon';
import {JpegIcon} from '../icons/JpegIcon';
import {JsonIcon} from '../icons/JsonIcon';
import {PngIcon} from '../icons/PngIcon';
import {WordIcon} from '../icons/Wordicon';
import {PrilohaInfo} from './PrilohaInfo';
import {PrilohaNazev} from './PrilohaNazev';
import {PrilohaAkce, PrilohaBase, PrilohyUtils, getFilenameExtension, povoleneTypyPriloh} from './utils/PrilohyUtils';

type Props = {
    /**
     * Příloha
     */
    readonly priloha: PrilohaBase;

    /**
     * Seznam akcí ke každé příloze
     */
    readonly actions: PrilohaAkce[];

    /**
     * Zda se mají výběry typu přílohy u jednotlivých nahrávaných souborů adaptovat na modifikované seznamy povolených typů příloh.
     */
    readonly dynamicPrilohaTypVyber?: boolean;

    /**
     * U nahrávané přílohy změní její typ podle ID
     */
    readonly onChangeTypPrilohy?: (id: number, typ: unknown) => void;

    /**
     * Varianta komponenty (výchozí je "simple")
     */
    readonly variant?: 'simple' | 'dialog';

    /**
     * Povolené typy příloh
     */
    readonly povoleneTypy?: unknown[];
};

declare const process: any;

/**
 * Komponenta zobrazuje jednu přílohu v seznamu.
 * Příloha může být zobrazena jako archivovaná (read-only) nebo jako k nahrání.
 * U příloh k nahrání se vybírá ještě typ přílohy, pokud je jich více.
 */
export const PrilohaListItem: FC<Props> = ({priloha, actions, onChangeTypPrilohy, povoleneTypy, dynamicPrilohaTypVyber}) => {
    /**
     * Combobox si potřebuje lokálně držet hodnotu pro zobrazení
     */
    const [selectedTypPrilohy, setSelectedTypPrilohy] = useState<string>('');

    const {id, nazev, typ, mime, errors, ...restProps} = priloha;
    const fileExt = PrilohyUtils.getFileExtensionByMimeType(mime).toUpperCase();
    const infoData = {mime: fileExt, ...restProps};

    const handleChangeTypPrilohy = (e: SelectChangeEvent<any>) => {
        const {value} = e.target;
        // Aktualizuje hodnotu pro combobox
        setSelectedTypPrilohy(value);
        if (onChangeTypPrilohy) {
            // Aktualizuje hodnotu pro upload přílohy v requestu
            onChangeTypPrilohy(id as number, value);
        }
    };

    // Kontroluje, zda je již specifikován typ přílohy.
    const hasPrilohaTyp = !!typ;
    // Pokud není specifikován typ přílohy, použije se error flash class k vyznačení selectu.
    const flashClassName = !hasPrilohaTyp ? FIELD_WITH_ERROR_FLASH_CLASS : undefined;

    const prilohaTypPocet = !!dynamicPrilohaTypVyber ? 0 : 1;

    if (process.env.NODE_ENV === 'development') {
        const shouldWarnAboutPovoleneTypyPriloh = !onChangeTypPrilohy && povoleneTypyPriloh;
        if (shouldWarnAboutPovoleneTypyPriloh) {
            // eslint-disable-next-line no-console
            console.warn('You might have not passed "onChangeTypPrilohy" handler to show list with allowed attachment types. Please provide the handler.');
        }
    }

    const iconByMime = (): ReactNode => {
        const mime = getFilenameExtension(priloha.nazev);

        switch (mime) {
            case 'doc':
            case 'docx':
                return <WordIcon />;
            case 'xls':
            case 'xlsx':
                return <ExcelIcon />;
            case 'pdf':
                return <PictureAsPdfOutlined />;
            case 'csv':
                return <CsvIcon />;
            case 'xml':
            case 'json':
                return <JsonIcon />;
            case 'jpg':
            case 'jpeg':
                return <JpegIcon />;
            case 'png':
                return <PngIcon />;
            default:
                return <AttachmentIcon />;
        }
    };

    const typPrilohy = PrilohaTyp[selectedTypPrilohy as keyof typeof PrilohaTyp];

    return (
        <>
            <ListItem
                key={id}
                secondaryAction={
                    <Span sx={{display: 'flex'}}>
                        {actions.map((action) => {
                            const handleClick = () => action.onClick(priloha);

                            if (!action.description) {
                                return (
                                    <IconButton
                                        data-testid={action.dataTestId}
                                        aria-label={action.ariaLabel}
                                        onClick={handleClick}
                                        disabled={action.disabled}
                                        key={action.nazev}
                                    >
                                        {action.icon}
                                    </IconButton>
                                );
                            }
                            return (
                                <TooltipIcon value={action.description} key={action.nazev}>
                                    <IconButton data-testid={action.dataTestId} aria-label={action.ariaLabel} onClick={handleClick} disabled={action.disabled}>
                                        {action.icon}
                                    </IconButton>
                                </TooltipIcon>
                            );
                        })}
                    </Span>
                }
            >
                <ListItemAvatar>
                    <Avatar
                        sx={{
                            backgroundColor: 'primary.main',
                            color: 'common.white',
                        }}
                    >
                        {iconByMime()}
                    </Avatar>
                </ListItemAvatar>
                <ListItemText primary={<PrilohaNazev nazev={nazev} typ={typ} />} secondary={<PrilohaInfo data={infoData} />} />
            </ListItem>
            <Div
                sx={{
                    width: 300,
                    marginLeft: povoleneTypy && povoleneTypy?.length > prilohaTypPocet ? 54 : 0,
                }}
                className={flashClassName}
            >
                {/* Ostatní chyby zobrazí tak jak přijdou */}
                {errors?.map((error, index) => (
                    <FormHelperText
                        key={index}
                        sx={{
                            marginTop: 0,
                            lineHeight: '1.5em',
                        }}
                        data-testid={`priloha.nazev.${error}`}
                        error
                    >
                        {error.message}
                    </FormHelperText>
                ))}
            </Div>
            <FormControl
                error={!hasPrilohaTyp}
                sx={{
                    width: 300,
                    marginLeft: 54,
                }}
                className={flashClassName}
            >
                {!!onChangeTypPrilohy && povoleneTypy && povoleneTypy.length > prilohaTypPocet && (
                    <Fragment>
                        {!hasPrilohaTyp && (
                            <InputLabel htmlFor="typ">
                                <FormattedMessage id="prilohy.vyberte.typ" />
                            </InputLabel>
                        )}
                        {/* 
                            Select bude mít následující value:
                            1) vezme value ze 'selectedTypPrilohy', pokud bude k dispozici,
                            2) pokud bude 'selectedTypPrilohy' mít hodnotu 'undefined', vezme value z 'typ' pokud bude k dispozici,
                            3) pokud bude 'selectedTypPrilohy' i 'typ' mít hodnotu 'undefined', použije se prázdný řetězec.
                            Tím se předchází warningu z Material-UI knihovny (Warning: Failed prop type: The prop `value` is marked as required in `ForwardRef(SelectInput)`, but its value is `undefined`.).
                            A také warningu z Reactu (Warning: A component is changing an uncontrolled input of type hidden to be controlled. Input elements should not switch from uncontrolled to controlled (or vice versa). Decide between using a controlled or uncontrolled input element for the lifetime of the component.).
                        */}
                        <Select value={selectedTypPrilohy || typ || ''} onChange={handleChangeTypPrilohy} inputProps={{name: `typ-${id}`, id: `typ-${id}`}}>
                            {povoleneTypy && selectedTypPrilohy && !povoleneTypy.includes(typPrilohy) && (
                                <MenuItem data-testid={`priloha-typ${name}`} value={selectedTypPrilohy}>
                                    <FormattedMessage id={`priloha.typ.${selectedTypPrilohy}`} />
                                </MenuItem>
                            )}
                            {povoleneTypy &&
                                povoleneTypy?.map((t, i) => {
                                    return (
                                        <MenuItem data-testid={`priloha-typ${name}`} value={t as any} key={i}>
                                            <FormattedMessage id={`priloha.typ.${t}`} />
                                        </MenuItem>
                                    );
                                })}
                        </Select>
                    </Fragment>
                )}
            </FormControl>
        </>
    );
};
