import {ApiDateTime, Maybe} from '@eon.cz/apollo13-graphql-vyjadrovaci-linka';
import {ReactNode} from 'react';
import {BackendEndpoints} from '../../../BackendEndpoints';
import {whiteSpace} from '../../../constants';
import {ValidationError} from '../../../utils/CommonTypes';
import {isNullOrUndefinedOrEmpty} from '../../../utils/CommonUtils';
import {BinIcon} from '../../icons/BinIcon';
import {DownloadIcon} from '../../icons/DownloadIcon';

export type PrilohaKNahrani = PrilohaBase & {
    readonly file: File;
};

/**
 * Informace o uploadovaných přílohách se předávají z klienta, aby validace nemuseli řešit načítání souborů.
 */
export type PrilohaUploadValidationInput = {
    readonly typ: any;
    readonly filename: string;
    readonly bytesLength: number;
};

/**
 * Základní property přílohy. Použito pro možnost zobrazení archivovaných příloh a příloh k nahrání jednou komponentou.
 */
export type PrilohaBase = {
    // ID archivované přílohy (string) nebo k nahrání (number)
    readonly id: string | number;
    readonly referenceId?: string | number;
    readonly nazev: string;
    readonly typ?: any;
    readonly mime: string;
    readonly velikost: number;
    readonly vytvoreno?: ApiDateTime;
    readonly podepsano?: ApiDateTime;
    readonly errors?: ValidationError[];
};

export type PrilohaAkce = {
    // Název akce (klíč)
    readonly nazev: string;

    // Data-test-id
    readonly dataTestId: string;

    // Název ikony, kterou použít
    readonly icon: ReactNode;

    // Popis pro přístupnost
    readonly ariaLabel?: string;

    // Popis, který se případně zobrazí v tooltipu
    readonly description?: ReactNode;

    // Zda je disabled
    readonly disabled?: boolean;

    // Handler při kliknutí
    readonly onClick: (item: PrilohaBase) => void;
};

export const SmazatAkceBase = {
    nazev: 'smazat',
    icon: <BinIcon color="error" />,
    ariaLabel: 'Delete',
    description: 'Smazat',
    dataTestId: `prilohy-delete-action`,
};

export const StahnoutAkce: PrilohaAkce = {
    nazev: 'stahnout',
    dataTestId: `prilohy-download-action`,
    icon: <DownloadIcon color="primary" />,
    ariaLabel: 'Download',
    description: 'Stáhnout',
    onClick: (item: PrilohaBase) => window.open(`${window.location.origin}/api/${BackendEndpoints.DOWNLOAD}/${item.id}`, '_blank'),
};

// Povolené formáty příloh
export const FileMimeTypeExtensionMap = [
    {ext: 'doc', mime: 'application/msword'},
    {ext: 'docx', mime: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'},
    {ext: 'xls', mime: 'application/vnd.ms-excel'},
    {ext: 'xlsx', mime: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'},
    {ext: 'pdf', mime: 'application/pdf'},
    {ext: 'csv', mime: 'text/csv'},
    {ext: 'xml', mime: 'application/xml'},
    {ext: 'xml', mime: 'text/xml'},
    {ext: 'jpg', mime: 'image/jpg'},
    {ext: 'jpeg', mime: 'image/jpeg'},
    {ext: 'gif', mime: 'image/gif'},
    {ext: 'png', mime: 'image/png'},
    {ext: 'tiff', mime: 'image/tiff'},
    {ext: 'txt', mime: 'text/plain'},
    {ext: 'msg', mime: 'application/vnd.ms-outlook'},
];

export const povoleneTypyPriloh = ['doc', 'docx', 'xls', 'xlsx', 'pdf', 'jpg', 'jpeg', 'gif', 'png', 'tiff', 'txt'];

export const PrilohyUtils = {
    /**
     * Zkonvertuje přílohy k nahrání do validačních objektů
     */
    prilohyToUploadValidationInput: (prilohy: PrilohaKNahrani[]): PrilohaUploadValidationInput[] =>
        prilohy.map((p) => {
            return {
                typ: p.typ,
                filename: p.nazev,
                bytesLength: p.velikost,
                mime: p.mime,
                id: p.id,
            } as PrilohaUploadValidationInput;
        }),

    /**
     * Zkonvertuje přílohy k nahrání do inputu
     */
    prilohyToUploadInput: async (prilohy: PrilohaKNahrani[]): Promise<any[]> => prilohy.map((priloha) => ({typ: priloha.typ, upload: priloha.file})),
    /**
     * Získá extension podle mime type, případně podle filename extension.
     */
    getFileExtension: (file: File): Maybe<string> => {
        if (!!file.type || file.type !== '') {
            return PrilohyUtils.getFileExtensionByMimeType(file.type);
        }
        return PrilohyUtils.getFilenameExtension(file.name) || '';
    },

    /**
     * Získá extension přímo z názvu souboru.
     */
    getFilenameExtension: (filename: string): Maybe<string> => {
        return getFilenameExtension(filename);
    },

    /**
     * Získa extension podle mime type z mapy. Pokud nenajde protihodnotu, vrací mime zpět.
     */
    getFileExtensionByMimeType: (mime: string) => {
        const extensionMimePair = FileMimeTypeExtensionMap.filter((ext) => ext.mime === mime)[0];
        return extensionMimePair && extensionMimePair.ext ? extensionMimePair.ext : mime;
    },
};

/**
 * Vrátí string za poslední tečkou v názvu souboru.
 * @param filename
 */
export const getFilenameExtension = (filename: string) => {
    if (!isNullOrUndefinedOrEmpty(filename)) {
        const splitted = filename.split('.');
        if (splitted.length > 1) {
            return splitted[splitted.length - 1] ?? '';
        }
    }
    return '';
};

export const getOriginalFilename = (filename: string) =>
    getFilenameExtension(filename)?.length === 3 || getFilenameExtension(filename)?.length === 4 ? filename?.substring(filename.lastIndexOf('.'), 0) : filename;

export const hasOriginalFilenameSpecialCharacters = (filename: string) => {
    const originalName = getOriginalFilename(filename);
    return (
        originalName
            ?.normalize('NFD')
            .replace(/[\u0300-\u036F]/g, '')
            .match(/\W/g) !== null
    );
};

export const splitterPriloha = <T extends string | undefined | null>({
    value,
    separator = '.',
    omitExt = true,
}: {
    value?: Maybe<T> | T;
    separator?: string;
    omitExt?: boolean;
}) => {
    const ext = getFilenameExtension(value ?? '');
    const filename = omitExt ? value : value?.substring(value.lastIndexOf(separator), 0);
    const originalNameWithoutDash =
        filename
            ?.normalize('NFD')
            .replace(/[\u0300-\u036F]/g, '')
            .replace(whiteSpace, '_')
            .replace(/\W/g, '') ?? '';
    return encodeURIComponent(omitExt ? originalNameWithoutDash : `${originalNameWithoutDash}.${ext}`);
};
