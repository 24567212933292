import {Grid, Typography} from '@mui/material';
import {FormattedMessage} from 'react-intl';
import {useMatches} from '../../utils/CommonUtils';
import {TooltipIcon} from '../helperComponents/TooltipIcon';

type Props = {
    readonly nazev: string;
    readonly typ?: unknown;
};

export const PrilohaNazev = ({nazev, typ}: Props) => {
    const matches = useMatches();

    return (
        <Grid container>
            <Grid item xs={12}>
                <Typography
                    component="span"
                    variant="subtitle1"
                    sx={{
                        ...(matches && {
                            maxWidth: 100,
                        }),

                        display: 'inline-block',
                        maxWidth: 230,
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        fontWeight: 'bold',
                    }}
                    data-testid={`priloha.nazev.${nazev}`}
                >
                    <TooltipIcon textRaw={nazev} placement="top">
                        <span>{nazev}</span>
                    </TooltipIcon>
                </Typography>
            </Grid>

            <Grid item xs={12}>
                {typ ? (
                    <Typography
                        component="span"
                        sx={{
                            display: 'inline-block',
                            //maxWidth: 100,
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            color: 'text.secondary',
                            fontSize: 13,
                        }}
                    >
                        &nbsp;
                        <FormattedMessage id={`priloha.typ.${typ}`} />
                    </Typography>
                ) : (
                    ''
                )}
            </Grid>
        </Grid>
    );
};
