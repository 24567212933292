import {ApiDateTime} from '@eon.cz/apollo13-graphql-web';
import {Typography} from '@mui/material';
import {Fragment, ReactNode} from 'react';
import {FormattedMessage} from 'react-intl';
import {isNullOrUndefinedOrEmpty} from '../../utils/CommonUtils';
import {FormattedDateTime} from '../date/FormattedDateTime';
import {PrilohaPodepsal} from './PrilohaPodepsal';
import {PrilohaVelikost} from './PrilohaVelikost';

type PrilohaInfoModel = {
    readonly mime: string;
    readonly velikost: number;
    readonly vytvoreno?: ApiDateTime;
    readonly podepsano?: ApiDateTime;
};

type Props = {
    readonly data: PrilohaInfoModel;
};

const getDataToRender = (data: PrilohaInfoModel) => {
    const dataToRender = [] as ReactNode[];
    if (data.vytvoreno) {
        dataToRender.concat(<FormattedDateTime value={data.vytvoreno} />);
    }
    dataToRender.concat(<PrilohaVelikost velikost={data.velikost} />);
    dataToRender.concat(data.mime);

    if (data.podepsano) {
        dataToRender.concat(<PrilohaPodepsal podepsano={data.podepsano} />);
    }

    return dataToRender;
};

export const PrilohaInfo = ({data}: Props) => {
    const info = getDataToRender(data);
    return (
        <Typography
            component="span"
            variant="subtitle1"
            sx={{
                fontSize: 12,
                color: 'text.secondary',
                display: 'inline',
            }}
        >
            {info.map((item, index) => {
                const isFirstItem = index === 0;

                // první prvek bez svislé čáry před sebou
                if (isFirstItem) {
                    return <Fragment key={`info-${index}`}>{item}</Fragment>;
                }

                // pokud je více info k dispozici, každý následující prvek bude mít před sebou svislou čáru
                if (!isNullOrUndefinedOrEmpty(item)) {
                    return (
                        <Fragment key={`info-${index}`}>
                            &nbsp;&nbsp;&nbsp;
                            <FormattedMessage id="|" />
                            &nbsp;&nbsp;&nbsp;{item}
                        </Fragment>
                    );
                }

                // pokud není co renderovat
                return null;
            })}
        </Typography>
    );
};
