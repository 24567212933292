import {CloudOffOutlined, CloudUpload as CloudUploadIcon} from '@mui/icons-material';
import {IconButton, Typography} from '@mui/material';
import {ReactElement, useEffect, useMemo} from 'react';
import {DropEvent, FileRejection, useDropzone} from 'react-dropzone';
import {FormattedMessage} from 'react-intl';
import {NotificationType} from '../../../models/notification/NotificationModel';
import {Div} from '../../styledComponents/Div';
import {useAddNotification} from '../notifications/actions/NotificationsActions';

type Props = {
    /**
     * Allow multiple files to be selected
     */
    readonly multiple?: boolean;

    /**
     * Show in more compact design
     */
    readonly compact?: boolean;

    /**
     * Bottom margin applied
     */
    readonly bottomMargin?: boolean;

    /**
     * Called when file(s) are dropped
     */
    readonly onDrop: (accepted: File[], rejected: FileRejection[], event: DropEvent) => void;

    /**
     * Title to show
     */
    readonly title?: string | ReactElement;
    /**
     * Limit
     */
    readonly limit?: number;
};

const baseStyle = {
    display: 'flex',
    flexGrow: 1,
    borderStyle: 'dashed',
    borderWidth: 3,
    borderColor: '#E5E5E5',
    textAlign: 'center' as any,
    justify: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    backgroundColor: 'background.default',
};

const notCompactStyle = {
    height: 150,
};

const bottomMarginStyle = {
    marginBottom: 16,
};

const activeStyle = {
    borderStyle: 'solid',
    borderColor: '#549EAC',
};

export const FileInput = ({multiple = true, onDrop, compact, bottomMargin, title, limit}: Props) => {
    const disabled = limit === 0;
    const {addNotification} = useAddNotification();
    const {getRootProps, getInputProps, isDragActive, fileRejections} = useDropzone({
        multiple: (limit ?? 1) > 1 ? multiple : false,
        onDrop,
        disabled,
        maxFiles: limit,
    });
    useEffect(() => {
        if (fileRejections.length > 0) {
            addNotification({
                text: <FormattedMessage id={'prilohy.k.nahrani.limit'} values={{pocet: limit}} />,
                type: NotificationType.ERROR,
            });
        }
    }, [addNotification, fileRejections, limit]);

    const style = useMemo(
        () => ({
            ...baseStyle,
            ...(!compact ? notCompactStyle : {}),
            ...(bottomMargin ? bottomMarginStyle : {}),
            ...(isDragActive ? activeStyle : {}),
        }),
        [isDragActive, bottomMargin, compact],
    );

    return (
        <Div sx={disabled ? {...baseStyle, ...notCompactStyle} : undefined} {...(disabled ? undefined : {...getRootProps({style})})}>
            <input {...getInputProps()} aria-label="Stažení souboru" disabled={disabled} data-testid={`file-input`} />
            <Div
                sx={(theme) => ({
                    width: '100%',
                    padding: theme.spacing(),
                    backgroundColor: theme.palette.background.default,
                })}
            >
                {title && typeof title === 'string' && (
                    <FormattedMessage id={title as string}>
                        {(message) => (
                            <IconButton
                                aria-label={message as unknown as string}
                                sx={{
                                    width: 55,
                                    height: 50,
                                }}
                            >
                                {disabled ? (
                                    <CloudOffOutlined
                                        sx={(theme) => ({
                                            width: 50,
                                            height: 50,
                                            backgroundColor: theme.palette.background.default,
                                            color: 'GrayText',
                                            borderRadius: '50%',
                                        })}
                                        color="inherit"
                                    />
                                ) : (
                                    <CloudUploadIcon
                                        sx={(theme) => ({
                                            width: 50,
                                            height: 50,
                                            backgroundColor: theme.palette.background.default,
                                            color: theme.palette.primary.main,
                                            borderRadius: '50%',
                                        })}
                                    />
                                )}
                            </IconButton>
                        )}
                    </FormattedMessage>
                )}
                {title && typeof title !== 'string' && title}
                <Typography>
                    {disabled ? (
                        <Typography color="GrayText" component="span">
                            <FormattedMessage id="form.fileInput.error" />
                        </Typography>
                    ) : (
                        <>
                            <FormattedMessage id="form.fileInput.message1" />{' '}
                            <Typography color="primary" component="span">
                                <FormattedMessage id="form.fileInput.message2" />
                            </Typography>
                        </>
                    )}
                </Typography>
            </Div>
        </Div>
    );
};
