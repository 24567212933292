import {PovoleneTypyPriloh} from '@eon.cz/apollo13-graphql-web';
import {
    Box,
    Checkbox,
    FormControl,
    FormControlLabel,
    FormHelperText,
    Input,
    InputAdornment,
    InputBaseComponentProps,
    InputLabel,
    MenuItem,
    Select,
    TextField,
    Typography,
} from '@mui/material';
import {ElementType, FunctionComponent, ReactNode, SyntheticEvent} from 'react';
import {Controller, FieldError, FieldPath, FieldValues, useFormContext} from 'react-hook-form';
import {FormattedMessage, useIntl} from 'react-intl';
import {KeyboardDatePickerField} from '../date/KeyboardDatePickerField';
import {FileInputField} from '../files/FileInputField';
import {PrilohaBase, PrilohaKNahrani} from '../files/utils/PrilohyUtils';
import {FIELD_WITH_ERROR_FLASH_CLASS} from '../helperComponents/ErrorFocus';
import {NumberFormatCustom} from '../helperComponents/NumberFormatCustom';
import {TooltipIcon} from '../helperComponents/TooltipIcon';

type SelectData = string;

type DateProps = {
    readonly minDate?: Date;
    readonly maxDate?: Date;
    readonly disableFuture?: boolean;
    readonly disablePast?: boolean;
};
type FileProps = {
    readonly handleDelete?: (prilohaId: string) => Promise<void>;
    /**
     * Seznam archivovaných příloh (= příloh už v databázi)
     */
    readonly archivovane?: PrilohaBase[];

    /**
     * Seznam příloh k nahrání - na začátku prázdný seznam, pak hodnota, která byla nastavena přes onChangeKNahrani
     */
    readonly kNahrani: PrilohaKNahrani[];

    /**
     * Maximální počet příloh, které je možné nahrát
     */
    readonly limit: number;

    /**
     * Povolené typy příloh
     */
    readonly povoleneTypyPriloh?: PovoleneTypyPriloh;

    /**
     * Kontroluje, zda byl vybrán typ přílohy u všech souborů
     */
    readonly isAllSetTypyPriloh: boolean;
    readonly isValidationError: boolean;
    /**
     * Voláno pokud se mají změnit přílohy k nahrání
     */
    readonly onChangeKNahrani: (kNahrani: PrilohaKNahrani[]) => void;

    /**
     * U nahrávané přílohy změní její typ podle ID v local state
     */
    readonly onChangeTypPrilohy: (id: number, typ: unknown) => void;

    /**
     * Nastaví hodnotu podle toho, jestli validace příloh vrátí chybu nebo ne
     */
    readonly setIsValidationError: (error: boolean) => void;
    readonly validateAllPrilohySize?: boolean;
};

type Props<T extends FieldValues> = {
    readonly label: string;
    readonly defaultValue?: any;
    readonly required?: boolean;
    readonly autofocus?: boolean;
    readonly multiline?: boolean;
    readonly error?: FieldError;
    readonly startAdornment?: JSX.Element;
    readonly disabled?: boolean;
    readonly selectData?: SelectData[];
    readonly tooltip?: string;
    readonly parseId?: string;
    readonly name: FieldPath<T>;
    readonly CustomComponent?: FunctionComponent<any>;
    readonly selectedItem?: unknown;
    readonly customSelectComponent?: ReactNode;
    readonly autocomplete?: 'given-name' | 'family-name' | 'email' | 'tel' | 'street-address' | 'postal-code' | 'country' | 'organization' | 'off';
    readonly customChange?: (event: unknown) => void;
    readonly isObec?: boolean;
    readonly placeholder?: string;
};

type BaseProps<T extends FieldValues> =
    | ({
          readonly type: 'text' | 'select' | 'number' | 'checkbox' | 'custom';
      } & Props<T>)
    | ({
          readonly type: 'file';
      } & FileProps &
          Props<T>)
    | ({
          readonly type: 'date';
      } & DateProps &
          Props<T>);

export const FormInput = <T extends FieldValues>(props: BaseProps<T>) => {
    const {
        type = 'text',
        required = false,
        disabled = false,
        name,
        label,
        startAdornment,
        tooltip,
        selectData,
        CustomComponent,
        customSelectComponent,
        autocomplete,
        autofocus,
        defaultValue,
        customChange,
        selectedItem,
        archivovane,
        povoleneTypyPriloh,
        handleDelete,
        isAllSetTypyPriloh,
        kNahrani,
        limit,
        onChangeKNahrani,
        onChangeTypPrilohy,
        setIsValidationError,
        isValidationError,
        minDate,
        maxDate,
        disableFuture,
        isObec,
        validateAllPrilohySize,
        parseId,
        multiline,
        placeholder,
        disablePast,
    } = props as BaseProps<T> & FileProps & DateProps;
    const {formatMessage} = useIntl();
    const {control} = useFormContext();

    if (type === 'select') {
        return (
            <Controller
                name={name}
                control={control}
                render={({field: {onChange, value, onBlur}, fieldState: {error}}) => (
                    <FormControl error={!!error} fullWidth margin="dense" className={error ? FIELD_WITH_ERROR_FLASH_CLASS : undefined}>
                        <InputLabel sx={{marginLeft: '-13px', marginTop: '3px'}}>
                            <Typography component="span">
                                <Box component="span" sx={{fontWeight: 'bold'}}>
                                    {`${formatMessage({id: label})}${required ? ' *' : ''}`}
                                </Box>
                            </Typography>
                        </InputLabel>
                        <Select
                            style={{marginBottom: 4}}
                            onBlur={onBlur}
                            value={value ?? ''}
                            onChange={onChange}
                            required
                            disabled={disabled}
                            autoComplete={autocomplete}
                            renderValue={(selected) => selected}
                            data-testid={`form-field-${name}`}
                            input={
                                <Input
                                    startAdornment={startAdornment}
                                    endAdornment={
                                        tooltip ? <TooltipIcon text={tooltip} placement="right-start" color="primary" fontSize="small" showIcon /> : undefined
                                    }
                                />
                            }
                            sx={{
                                '& .MuiSelect-icon': {
                                    color: 'primary.main',
                                    marginRight: tooltip ? 1.3 : undefined,
                                },
                            }}
                        >
                            {!customSelectComponent &&
                                selectData?.map((item) => (
                                    <MenuItem key={item} value={item}>
                                        {item}
                                    </MenuItem>
                                ))}
                            {customSelectComponent}
                        </Select>
                        {!!error && <FormHelperText sx={{marginLeft: '0px'}}>{error.message}</FormHelperText>}
                    </FormControl>
                )}
            />
        );
    }

    if (type === 'text' || type === 'number') {
        return (
            <Controller
                control={control}
                name={name}
                render={({field, fieldState: {error}}) => (
                    <TextField
                        {...field}
                        id={field.name}
                        value={field.value ?? ''}
                        autoComplete={autocomplete}
                        disabled={disabled}
                        className={error ? FIELD_WITH_ERROR_FLASH_CLASS : undefined}
                        InputProps={{
                            inputComponent: type === 'number' ? (NumberFormatCustom as ElementType<InputBaseComponentProps>) : undefined,
                            startAdornment: startAdornment ? <InputAdornment position="start">{startAdornment}</InputAdornment> : undefined,
                            endAdornment: tooltip ? (
                                <TooltipIcon text={tooltip} placement="right-start" color="primary" fontSize="small" showIcon />
                            ) : undefined,
                        }}
                        label={
                            <Typography component="span">
                                <Box component="span" sx={{fontWeight: 'bold'}}>
                                    {formatMessage({id: label})}
                                </Box>
                            </Typography>
                        }
                        fullWidth
                        variant="standard"
                        multiline={multiline}
                        rows={multiline ? 10 : undefined}
                        margin="dense"
                        error={!!error}
                        helperText={error?.message}
                        required={required}
                        autoFocus={autofocus}
                        data-testid={`form-field-${name}`}
                    />
                )}
            />
        );
    }

    if (type === 'checkbox') {
        return (
            <Controller
                name={name}
                control={control}
                render={({field: {onChange, onBlur, value, name}, fieldState: {error}}) => (
                    <FormControl error={!!error} fullWidth margin="dense" className={error ? FIELD_WITH_ERROR_FLASH_CLASS : undefined}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    name={name}
                                    checked={value}
                                    value={value}
                                    onChange={onChange}
                                    onBlur={onBlur}
                                    color="primary"
                                    disabled={disabled}
                                    required={required}
                                    data-testid={`form-field-${name}`}
                                />
                            }
                            label={formatMessage({id: label})}
                        />
                        {!!error?.message && (
                            <Box>
                                <FormHelperText error>{error.message}</FormHelperText>
                            </Box>
                        )}
                    </FormControl>
                )}
            />
        );
    }
    if (type === 'date') {
        return (
            <Controller
                control={control}
                name={name}
                render={({field: {onChange, value, onBlur}, fieldState: {error}}) => (
                    <KeyboardDatePickerField
                        name={name}
                        value={value}
                        onBlur={onBlur}
                        onChange={onChange}
                        label={formatMessage({id: label})}
                        error={!!error?.message}
                        helperText={error ? error.message : null}
                        showRequired={required}
                        minDate={minDate}
                        maxDate={maxDate}
                        disableFuture={disableFuture}
                        disablePast={disablePast}
                        data-testid={`form-field-${name}`}
                        tooltip={<TooltipIcon text={tooltip} placement="right-start" color="primary" fontSize="small" showIcon />}
                    />
                )}
            />
        );
    }
    if (type === 'custom' && CustomComponent) {
        return (
            <Controller
                control={control}
                name={name}
                render={({field: {onChange, value, onBlur}, fieldState: {error, isTouched}}) => {
                    const handleOnChange = (event: SyntheticEvent<Element, Event>) => {
                        customChange?.(event);
                        onChange(event);
                    };
                    return (
                        <CustomComponent
                            {...{error, disabled, label}}
                            selectedItem={selectedItem}
                            name={name}
                            value={value ?? ''}
                            onBlur={onBlur}
                            onChange={handleOnChange}
                            input={{onChange: handleOnChange, value, onBlur: onBlur}}
                            meta={{error, touched: isTouched}}
                            isObec={isObec}
                            placeholder={placeholder}
                            data-testid={`form-field-${name}`}
                        />
                    );
                }}
            />
        );
    }

    if (type === 'file') {
        return (
            <>
                <Controller
                    name={name}
                    control={control}
                    defaultValue={defaultValue}
                    render={({field: {onChange}, fieldState: {error}}) => (
                        <FileInputField
                            kNahrani={kNahrani}
                            archivovane={archivovane}
                            onChangeKNahrani={onChangeKNahrani}
                            isAllSetTypyPriloh={isAllSetTypyPriloh}
                            onChangeTypPrilohy={onChangeTypPrilohy}
                            povoleneTypyPriloh={povoleneTypyPriloh}
                            limit={limit}
                            onDelete={handleDelete}
                            setIsValidationError={setIsValidationError}
                            onChange={onChange}
                            data-testid={`form-field-${name}`}
                            customErrors={[]}
                            validateAllPrilohySize={validateAllPrilohySize}
                            error={!!error}
                            helperText={error?.message}
                            name={name}
                            required={required}
                            typPrilohy={'ZVYJ'}
                            parseId={parseId}
                        />
                    )}
                />
                {isValidationError && (
                    <FormHelperText
                        data-testid={`form-field-error-${name}`}
                        sx={{
                            color: 'error.main',
                        }}
                    >
                        <FormattedMessage id="priloha.nejde.vlozit" />
                    </FormHelperText>
                )}
            </>
        );
    }

    return null;
};
