import {FormatDateOptions, FormattedDate, FormattedTime} from 'react-intl';

type Props = {
    /**
     * Value to show
     */
    readonly value?: string | number | Date | undefined | null;

    /**
     * Props for FormattedDate component (most interesting is format)
     */
    readonly dateProps?: Partial<FormatDateOptions>;

    /**
     * Props for FormattedTime component (most interesting is format)
     */
    readonly timeProps?: Partial<FormatDateOptions>;
};
export const FormattedDateTime = ({value, dateProps, timeProps}: Props) => {
    if (!value) {
        return null;
    }
    return (
        <>
            <FormattedDate {...dateProps} value={value} />
            &nbsp;
            <FormattedTime {...timeProps} value={value} />
        </>
    );
};
